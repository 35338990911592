import { Link } from 'react-router-dom'

const NotFound = () => {
	return (
		<div className='h-[100vh] flex justify-center items-center w-full'>
			<div className='w-full flex flex-col justify-center items-center h-full gap-4'>
				<p className='text-xl text-black'>صفحه مورد نظر یافت نشد...</p>
				<p className='text-lg text-redblack'> 404</p>
				<Link to='/' className='bg-gray-300 px-4 py-2 rounded-xl'>بازگشت</Link>
			</div>
		</div>
	)
}

export default NotFound
