import { combineReducers } from '@reduxjs/toolkit'
import profile from './slices/profile'
import auth from './slices/auth'
import notifications from './slices/notifications'

export const rootReducer = combineReducers({
	profile: profile,
	auth: auth,
	notifications: notifications

})
