import axiosInstance from 'helper/axiosInstance'
import { async } from 'q'

export const getUserProfileAPI = async () => {
	const response = await axiosInstance.get('/dashboard/profile')
	return response
}

export const updatePasswordAPI = async (id, data) => {
	const response = await axiosInstance.patch(
		`/dashboard/update/password/${id}`,
		data
	)
	return response
}

export const loginAPI = async data => {
	const response = await axiosInstance.post('/auth/login', data)
	return response
}

export const logOutAPI = async () => {
	const response = await axiosInstance.post('/auth/logout')
	return response
}
