import axios from 'axios'
import { toast } from 'react-toastify'
const axiosInstance = axios.create({
	baseURL: 'https://api.mashghool.com/api/'
})

axiosInstance.interceptors.request.use(
	function (config) {
		const token = localStorage.getItem('accessToken')

		let Authorization = ''
		// if (config.headers.Authorization) {
		// 	Authorization = ''
		// }
		if (token) {
			Authorization = `Bearer ${token}`
		}

		config.headers = {
			...config.headers,
			Authorization
		}

		return config
	},
	function (error) {
		// Do something with request error
		return Promise.reject(error)
	}
)

// Add a response interceptor
axiosInstance.interceptors.response.use(
	function (response) {
		// Any status code that lie within the range of 2xx cause this function to trigger
		// Do something with response data
		return response
	},
	function (error) {
		// Any status codes that falls outside the range of 2xx cause this function to trigger
		// Do something with response error
		if (error.code === 'ERR_NETWORK' || error.code === 'ECONNABORTED') {
			toast.error('مشکل در برقراری ارتباط با سرور')
		} else {
			if (error.response.status === 400) {
				toast.error(error.response.data.message)
			}

			if (error.response.status === 401) {
				toast.error('لطفا ابتدا در سایت لاگین کنید.')

				localStorage.removeItem('loggedInToken')
				localStorage.removeItem('persist:root')
				localStorage.removeItem('userInfo')

				// window.location.reload()
			}

			if (error.response.status === 403) {
				// navigate('/acceptCode')
			}

			if (error.response.status === 413) {
				toast.error('حجم فایل بیشتر از حجم مجاز است.')
			}

			if (error.response.status === 432) {
				// navigate('/acceptPhone')
				toast.error(error.response.data.message.user[0])
			}

			if (error.response.status === 422 || error.response.status === 404) {
				toast.error(error.response.data.message)
				return error.response
			}

			if (error.response.status === 500) {
				toast.error('500 Internal Server Error')
			}
		}
		return Promise.reject(error)
	}
)
export default axiosInstance
