import { createSlice } from '@reduxjs/toolkit'

const initialState = {}

const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		getLog: () => console.log('object')
	}
})

export const { getLog } = authSlice.actions
export default authSlice.reducer
