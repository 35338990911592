// router
import { useEffect } from 'react'
import { useCookies } from 'react-cookie'
import { useDispatch } from 'react-redux'
import { Outlet } from 'react-router-dom'
import { getAllNotification } from 'redux/slices/notifications'
import { getUserProfile } from 'redux/slices/profile'

function App() {
	const dispatch = useDispatch()
	const [cookies, setCookies, deleteCookie] = useCookies(['accessToken'])
	useEffect(() => {
		if (cookies.accessToken !== undefined || cookies.accessToken) {
			dispatch(getUserProfile())
			dispatch(getAllNotification())
		}
	}, [])

	return (
		<>
			<Outlet />
		</>
	)
}

export default App
