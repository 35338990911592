import { async } from 'q'
import axiosInstance from '../helper/axiosInstance'

export const getAllNotificationsAPI = async () => {
	const response = await axiosInstance.get('/dashboard/notification/index')
	return response
}

export const readNotificationAPI = async id => {
	const response = await axiosInstance.post(`/dashboard/notification/${id}/read`)
	return response
}
