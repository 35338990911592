import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { getAllNotificationsAPI } from '../../services/notificationAPIs'

export const getAllNotification = createAsyncThunk(
	'/notifications/getAll',
	async () => {
		const response = await getAllNotificationsAPI()
		return response
	}
)

const notificationsSlice = createSlice({
	name: 'notifications',
	initialState: {
		status: 'idle',
		loading: true,
		notifications: {
			data: []
		},
		error: null
	},
	reducers: {},
	extraReducers: builder => {
		builder
			.addCase(getAllNotification.pending, state => {
				state.loading = true
				state.status = 'loading'
			})
			.addCase(getAllNotification.fulfilled, (state, action) => {
				if (action.payload.status === 200) {
					state.notifications = action.payload.data.notifications
				}
				state.status = 'completed'

				state.loading = false
			})
			.addCase(getAllNotification.rejected, (state, action) => {
				state.loading = false
				state.status = 'failed'
				state.error = action.error.message
			})
	}
})

export default notificationsSlice.reducer
