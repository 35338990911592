import { useEffect } from 'react'
import { useCookies } from 'react-cookie'
import { useNavigate } from 'react-router-dom'

const ProtectedRoute = ({ component }) => {
	const [cookies, setCookies, deleteCookie] = useCookies(['accessToken'])
	const navigate = useNavigate()

	// const dispatch = useDispatch()
	useEffect(() => {
		if (cookies.accessToken === undefined || !cookies.accessToken) {
			navigate('/login')
			localStorage.removeItem('userInfo')
			localStorage.removeItem('loggedInToken')
			deleteCookie('accessToken', { domain: '.mashghool.com' })
		} else {
			localStorage.setItem('accessToken', cookies.accessToken)
			if (window.location.pathname === '/login') navigate('/')
		}
	}, [])
	// dispatch(setToken(token))
	// dispatch(setIsloggedIn(true))

	return <>{component}</>
}

export default ProtectedRoute
