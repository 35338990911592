import React, { lazy, Suspense } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import App from '../App'
import Loading from '../components/common/ProgressCircle/Loading'
import ProtectedRoute from './ProtectedRoute'
import NotFound from 'pages/404'

const Home = lazy(() => import('./../pages/Home'))
const Profile = lazy(() => import('./../pages/Profile'))
const JobPosition = lazy(() => import('./../pages/JobPosition'))
const Messages = lazy(() => import('./../pages/Messages'))
const UserAccount = lazy(() => import('../pages/UserAccount'))
const Menu = lazy(() => import('../pages/Menu'))
const Login = lazy(() => import('../pages/Login'))

function Router() {
	return (
		<BrowserRouter>
			<Suspense
				fallback={
					<div className='w-full h-[100vh] flex justify-center items-center'>
						<Loading />
					</div>
				}>
				<Routes>
					<Route path='/' element={<ProtectedRoute component={<App />} />}>
						{/* <Route index element={<ProtectedRoute component={<Profile />} />} /> */}
						<Route
							path='/'
							element={<ProtectedRoute component={<Profile />} />}
						/>
						<Route
							path='/JobPosition'
							element={<ProtectedRoute component={<JobPosition />} />}
						/>
						<Route
							path='/Menu'
							element={<ProtectedRoute component={<Menu />} />}
						/>
						<Route
							path='/Messages'
							element={<ProtectedRoute component={<Messages />} />}
						/>
						<Route
							path='/Useraccount'
							element={<ProtectedRoute component={<UserAccount />} />}
						/>
						<Route path='/login' element={<Login />} />
						<Route path='*' element={<NotFound />} />
					</Route>
				</Routes>
			</Suspense>
		</BrowserRouter>
	)
}

export default Router
