import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { getUserProfileAPI } from '../../services/userProfileAPI'

export const getUserProfile = createAsyncThunk(
	'/user/userProfile',
	async () => {
		const response = await getUserProfileAPI()
		return response
	}
)

const profileSlice = createSlice({
	name: 'profile',
	initialState: {
		profile: {
			name: '',
			last_name: '',
			email: '',
			userJobOption: [],
			upload: [],
			Profile: {
				cell_number: '',
				freelance_exp: '',
				job_title: '',
				date_of_birth: '',
				disability: '',
				country: '',
				city: '',
				address: '',
				gender: ''
			}
		},
		loading: true,
		status: 'idle',
		error: null
	},
	reducers: {},
	extraReducers: builder => {
		builder
			.addCase(getUserProfile.pending, state => {
				state.loading = true
				state.status = 'loading'
			})
			.addCase(getUserProfile.fulfilled, (state, action) => {
				// if (action.payload.status === 200) {
				state.profile = action.payload.data.user
				// }
				state.status = 'completed'
				state.loading = false
			})
			.addCase(getUserProfile.rejected, (state, action) => {
				state.loading = false
				state.status = 'failed'
				state.error = action.error.message
			})
	}
})

export default profileSlice.reducer
